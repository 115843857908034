<template>
  <div class="wallet-list">
    <!-- External components -->
    <wallet-details-modal
      :wallet="selectedWallet"
      :open-modal="detailModal"
      @modalClosed="closeDetailsModal"
      @onEditButtonPress="(_wal) => (selectedWallet = _wal, createUpdateModal = true, isUpdate = true)"
      @onDeleteButtonPress="(_wal, _status) => updateWalletStatus(_wal, _status)"
      @onAdjustButtonPress="adjustForm = true, createUpdateModal = true"
    />
    <wallet-form-modal
      :wallet="selectedWallet"
      :open-modal="createUpdateModal"
      :update="isUpdate"
      :adjust-form="adjustForm"
      @modalClosed="createUpdateModal = false, selectedWallet = {}, isUpdate = false, detailModal = false, adjustForm = false"
    />
    <my-alert ref="confirm" />
    <v-btn
      dark
      fixed
      bottom
      right
      fab
      class="mb-8 mr-5"
      color="primary"
      @click="createUpdateModal = true"
    >
      <v-icon>mdi-plus</v-icon>
    </v-btn>

    <v-list>
      <h1 class="title">
        Wallets
      </h1>
      <v-divider />
      <p
        class="text-center pt-5"
        v-if="wallets.length === 0"
      >
        No data found.
      </p>
      <v-list-item
        v-for="wallet in wallets"
        :key="wallet.id"
      >
        <v-list-item-avatar>
          <v-icon
            class="primary lighten-2"
            dark
          >
            mdi-wallet-outline
          </v-icon>
        </v-list-item-avatar>

        <v-list-item-content
          class="wallet-content"
          @click="openDetailsModal(wallet)"
        >
          <v-list-item-title>{{ wallet.name }}</v-list-item-title>
          <v-list-item-subtitle>{{ prepareWalletSubtitle(wallet) }}</v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action v-if="wallet.status === 1">
          <v-btn
            small
            icon
            color="info"
            @click.prevent="openEditModal(wallet)"
          >
            <v-icon>
              mdi-square-edit-outline
            </v-icon>
          </v-btn>
        </v-list-item-action>
        <v-list-item-action v-if="wallet.status !== 1">
          <v-btn
            small
            icon
            color="info"
            @click.prevent="updateWalletStatus(wallet, 1)"
          >
            <v-icon>
              mdi-arrow-left-top
            </v-icon>
          </v-btn>
        </v-list-item-action>
        <v-list-item-action
          class="ml-2"
          v-if="wallet.status === 1"
        >
          <v-btn
            icon
            small
            class="white--text"
            color="brown"
            @click="updateWalletStatus(wallet, 2)"
          >
            <v-icon>
              mdi-archive
            </v-icon>
          </v-btn>
        </v-list-item-action>
        <v-list-item-action
          class="ml-2"
          v-if="wallet.status !== 0"
        >
          <v-btn
            icon
            small
            class="white--text"
            color="red"
            @click="updateWalletStatus(wallet, 0)"
          >
            <v-icon>
              mdi-delete
            </v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>
    </v-list>
  </div>
</template>


<script>
import WalletDetailsModal from './WalletDetailsModal.vue'
import WalletFormModal from './WalletFormModal.vue'
import MyAlert from "@/components/common/MyAlert";

export default {
  components: { WalletDetailsModal, WalletFormModal, MyAlert },
  name: "WalletList",
  data() {
    return {
      selectedWallet: {},
      detailModal: false,
      adjustForm: false,
      createUpdateModal: false,
      isUpdate: false,
      loading: false
    }
  },
  computed: {
    wallets() {
      return JSON.parse(JSON.stringify(this.$store.getters.wallets))
    }
  },
  async mounted() {
    await this.$store.dispatch('getAllWalletsFromAPI')
    if (this.$route.query.walletId) {
      this.selectWalletFromRouter(this.$route.query.walletId)
    }
  },
  methods: {
    prepareWalletSubtitle(wallet) {
      return `Amount left: ${wallet.current_amount}`
    },
    selectWalletFromRouter(walletId) {
      const _wallet = this.wallets.find((item) => {
        return item.id === walletId
      })
      if (_wallet) {
        this.selectedWallet = _wallet
        this.detailModal = true
      } else {
        this.$ebus.$emit("newToastMessage", {
          message: "Wallet not found.",
          color: "error"
        })
      }
    },
    openDetailsModal(wallet) {
      this.$router.push({ path: this.$route.path, query: { walletId: wallet.id } })
    },
    closeDetailsModal() {
      this.$router.push({ path: this.$route.path })
      this.selectedWallet = {}
      this.detailModal = false
    },
    openEditModal(wallet) {
      this.selectedWallet = wallet
      this.createUpdateModal = true
      this.isUpdate = true
    },
    async updateWalletStatus(wallet, status) {
      let modalTitle = "Delete!!"
      let modalSubtitle = "Are you sure about delete?"
      if (status === 2) {
        modalTitle = "Archive!!"
        modalSubtitle = "Are you sure about archive?"
      } else if (status === 1) {
        modalTitle = "Restore!!"
        modalSubtitle = "Are you sure about restore?"
      }
      this.loading = true
      if (
        await this.$refs.confirm.open({
          title: modalTitle,
          description: modalSubtitle,
        })
      ) {
        const response = await this.$store.dispatch("deleteWalletAction", { walletId: wallet.id, status: status });
        if (response && response.status === 200) {
          this.loading = false
          this.detailModal = false
        } else {
          this.loading = false
        }
        this.$ebus.$emit("newToastMessage", {
          message: response.msg,
        });
      } else {
        this.loading = false;
      }
    },
  },
  watch: {
    '$route.query.walletId'(newValue) {
      if (newValue) {
        this.selectWalletFromRouter(newValue)
      }
    }
  }
}
</script>

<style scoped>
.wallet-list {
  margin-top: 24px;
  margin-left: 5px;
  margin-right: 5px;
}

.wallet-content {
  cursor: pointer;
}
</style>