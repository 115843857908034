<template>
  <v-dialog
    v-model="open"
    scrollable
    max-width="760px"
  >
    <v-card>
      <v-card-title v-if="!adjustForm">
        {{ update ? `Update "${wallet.name}"` : 'Create new wallet' }}
      </v-card-title>
      <v-card-title v-else>
        Adjust balance
      </v-card-title>
      <v-card-subtitle
        v-if="adjustForm"
        class="red--text"
      >
        Adjusting the balance is not good practice for your finance. 
        So, please avoid this and create some transactions so that you can track your money in future.
        But still you have option to update your money anyway.
      </v-card-subtitle>
      <v-divider />
      <v-card-text>
        <wallet-form
          v-if="!adjustForm"
          :wallet="wallet"
          :update="update"
          @closeModal="open = false"
        />
        <wallet-adjust-balance-form
          v-else
          :wallet="wallet"
          @closeModal="open = false"
        />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import WalletAdjustBalanceForm from './WalletAdjustBalanceForm.vue'
import WalletForm from './WalletForm.vue'

export default {
  components: { WalletForm, WalletAdjustBalanceForm },
	name: "WalletFormModal",
	props: {
		wallet: {
			type: Object,
			required: false,
			default: () => {}
		},
		update: {
			type: Boolean,
			default: false
		},
		openModal: {
			type: Boolean,
			default: false
		},
		adjustForm: {
			type: Boolean,
			default: false
		}
	},
	data () {
		return {
			open: false,
		}
	},
	watch: {
		openModal (newValue) {
			if (newValue) {
				this.open = true
			}
		},
		open (newValue) {
			if (newValue === false) {
				this.$emit("modalClosed")
			}
		}
	}
}
</script>