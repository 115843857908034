<template>
  <wallet-list />
</template>

<script>
import WalletList from "@/components/money/wallet/WalletList.vue"

export default {
  name: "WalletAndCategoryListView",
  components: { WalletList }
}
</script>