<template>
  <div>
    <div class="text-center">
      <v-overlay :value="loading">
        <v-progress-circular
          v-if="loading"
          width="7"
          size="70"
          indeterminate
        />
      </v-overlay>
    </div>
    <v-list>
      <p
        class="text-center pt-5"
        v-if="histories.length === 0"
      >
        No data found.
      </p>
      <v-list-item
        v-for="history in histories"
        :key="history.id"
      >
        <v-list-item-avatar>
          <v-icon
            :class="history.sign === '+' ? 'blue lighten-2' : 'red lighten-2'"
            dark
          >
            mdi-cash
          </v-icon>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title>
            {{ history.trx_type }}: <span :class="history.sign === '+' ? 'blue--text' : 'red--text'">{{ history.sign }}{{
              history.amount }}</span>
          </v-list-item-title>
          <v-list-item-subtitle>{{ history.note }}</v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-icon>
          <span>{{ history.transaction_time | dateToHuman }}</span>
        </v-list-item-icon>
      </v-list-item>
    </v-list>
    <div>
      <v-btn
        block
        color="info"
        :loading="loading"
        v-if="historyPagination.next"
        @click="getTransactionHistory(true)"
      >
        More
      </v-btn>
    </div>
  </div>
</template>

<script>
import { _get } from '@/utils/api-request'
export default {
  name: "WalletTransactionHistory",
  props: {
    wallet: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      histories: [],
      historyPagination: {},
      loading: false,
    }
  },
  created() {
    this.getTransactionHistory()
  },
  methods: {
    async getTransactionHistory(append = false) {
      this.loading = true
      const path = this.historyPagination.next ? `v1/money/wallets/${this.wallet.id}/histories${this.historyPagination.next}` : `v1/money/wallets/${this.wallet.id}/histories`
      const response = await _get({ path: path })
      if (response && response.status === 200) {
        if (append) {
          this.histories.push(...response.data.data)
        } else {
          this.histories = response.data.data
        }
        this.historyPagination = response.data.pagination
      }
      this.loading = false
    }
  },
  watch: {
    'wallet.id'(newValue) {
      if (newValue) {
        this.getTransactionHistory()
      }
    }
  },
  filters: {
    dateToHuman(value) {
      return new Date(value).toLocaleDateString()
    },
  }
}
</script>