<template>
  <v-dialog
    v-model="open"
    scrollable
    max-width="760px"
  >
    <v-card>
      <v-card-title>{{ wallet.name }}</v-card-title>
      <v-divider />
      <v-card-text>
        <!-- Loads the tabs here -->
        <v-tabs v-model="walletTab">
          <v-tab>Details</v-tab>
          <v-tab>Transaction History</v-tab>
        </v-tabs>
        <!-- Loads the tabs value here... -->
        <v-tabs-items
          v-model="walletTab"
          centered
        >
          <v-tab-item>
            <key-value-pair-data
              :data="wallet"
              :date-time-fields="dateTimeFields"
              :ignored-fields="ignoredFieldsToShow"
              :sort="true"
            />
          </v-tab-item>
          <v-tab-item>
            <wallet-transaction-history :wallet="wallet" />
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>

      <v-card-actions class="justify-end">
        <v-btn
          small
          color="indigo"
          class="white--text"
          @click="$emit('onAdjustButtonPress')"
        >
          <v-icon small>
            mdi-scale-balance
          </v-icon>
          Adjust Balance
        </v-btn>
        <v-btn
          v-if="wallet.status === 1"
          color="info"
          small
          @click="$emit('onEditButtonPress', wallet)"
        >
          <v-icon small>
            mdi-square-edit-outline
          </v-icon>
          Edit
        </v-btn>
        <v-btn
          v-if="wallet.status === 1"
          class="white--text"
          color="brown"
          small
          @click="$emit('onDeleteButtonPress', wallet, 2)"
        >
          <v-icon small>
            mdi-archive
          </v-icon>
          Archive
        </v-btn>
        <v-btn
          v-if="wallet.status !== 0"
          class="white--text"
          color="red"
          small
          @click="$emit('onDeleteButtonPress', wallet, 0)"
        >
          <v-icon small>
            mdi-delete
          </v-icon>
          Delete
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import KeyValuePairData from '../../common/KeyValuePairData.vue'
import WalletTransactionHistory from './WalletTransactionHistory.vue'

export default {
  components: { KeyValuePairData, WalletTransactionHistory },
  name: "WalletDetailsModal",
  props: {
    wallet: {
      type: Object,
      required: true
    },
    openModal: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      open: false,
      ignoredFieldsToShow: ["id", "author", "initial_amount"],
      dateTimeFields: ["created_at", "updated_at"],
      walletTab: null
    }
  },
  watch: {
    openModal(newValue) {
      this.open = newValue
    },
    open(newValue) {
      if (newValue === false) {
        this.$emit("modalClosed")
      }
    }
  }
}
</script>