<template>
  <form @submit.prevent="saveWallet">
    <v-text-field
      :rules="[(v) => !!v || 'This field is required.']"
      label="Name"
      v-model="formData.name"
      placeholder="Wallet name"
    />
    <v-select
      clearable
      :items="walletTypes"
      v-model="formData.type"
      item-text="label"
      item-value="value"
      label="Wallet Type"
    />
    <v-textarea
      name="description"
      v-model="formData.description"
      label="Wallet description"
      rows="2"
      hint="Write more about your wallet."
    />
    <v-text-field
      v-if="!update"
      :rules="[(v) => !!v || 'This field is required.']"
      type="number"
      :label="`${formData.type === 'credit' ? 'Limit': 'Initial Amount'}`"
      v-model="formData.initial_amount"
      placeholder="Wallet Initial amount"
    />
    <v-row>
      <v-col
        cols="12"
        sm="4"
        md="4"
      >
        <v-switch
          v-model="auto_adjust_switch"
          label="Has auto adjustment?"
          color="primary"
          hide-details
        />
      </v-col>
      <v-col
        v-if="auto_adjust_switch"
        cols="12"
        sm="8"
        md="8"
      >
        <v-select
          v-model="formData.auto_adjust"
          :items="['monthly']"
          label="Balance adjust threasold"
        />
      </v-col>
    </v-row>
    <v-text-field
      v-if="auto_adjust_switch"
      :rules="[(v) => (v <= 30 && v >= 1) || 'Day should be within 1-30.']"
      type="number"
      label="Day of month"
      v-model="formData.reset_day_in_month"
      placeholder="Enter a day 1-30"
    />
    
    <p
      class="mt-4 red pa-2 white--text"
      v-if="errorResponse"
    >
      {{ errorResponse }}
    </p>
    <v-btn
      type="submit"
      class="mt-4"
      :color="update ? 'info' : 'primary'"
    >
      {{ update ? 'Update' : 'Create' }}
    </v-btn>
  </form>
</template>

<script>
import { findoutTheErrorMsg } from '@/utils/error-parser.js'

export default {
  name: "WalletForm",
  data () {
    return {
      formData: this.wallet || {type: 'general'},
      auto_adjust_switch: !!this.wallet.auto_adjust || false,
      errorResponse: null,
      walletTypes: [{label: "General", value: 'general'}, {label: "Credit", value: 'credit'}, ]
    }
  },
  props: {
    wallet: {
      type: Object,
      required: false,
      default: () => {}
    },
    update: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    async saveWallet () {
      const response = await this.$store.dispatch("createOrUpdateWalletAction", this.formData)
      if (response && (response.status === 201 || response.status === 200)) {
        this.formData = {}
        this.$ebus.$emit("newToastMessage", {
          message: response.msg,
        });
        this.$emit("closeModal")
      } else {
        this.errorResponse = findoutTheErrorMsg(response.data)
      }
    }
  },
  watch: {
    'wallet.id' (newValue) {
      if (newValue) {
        this.formData = JSON.parse(JSON.stringify(this.wallet))
      }
      if (this.wallet.auto_adjust) {
        this.auto_adjust_switch = true
      }
    },
    auto_adjust_switch (newValue) {
      if (newValue === false) {
        this.formData.auto_adjust = null
      } else {
        this.wallet.auto_adjust = 'monthly'
      }
    }
  }
}
</script>