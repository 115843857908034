<template>
  <form @submit.prevent="updateBalance">
    <v-text-field
      type="number"
      :label="`${wallet.type === 'credit' ? 'Available Balance': 'Current Balance'}`"
      v-model="formData.current_amount"
      placeholder="Wallet current amount"
    />
    <v-btn
      color="red"
      class="white--text"
      type="submit"
    >
      <v-icon>
        mdi-scale-balance
      </v-icon>
      Update Amount
    </v-btn>
  </form>
</template>

<script>
import { findoutTheErrorMsg } from '@/utils/error-parser.js'

export default {
  name: "WalletBalanceAdjust",
  props: {
    wallet: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      formData: this.wallet || {}
    }
  },
  methods: {
    async updateBalance () {
      const response = await this.$store.dispatch("adjustWalletBalanceAction", this.formData)
      if (response && response.status === 200) {
        this.$ebus.$emit("newToastMessage", {
          message: response.msg,
        });
        this.$emit("closeModal")
      } else {
        this.errorResponse = findoutTheErrorMsg(response.data)
      }
    }
  },
  watch: {
    'wallet.id' () {
      this.formData = JSON.parse(JSON.stringify(this.wallet))
    }
  }
}
</script>